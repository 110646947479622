<template>
  <div>
    <draggable 
      v-model="fileList"
      class="upload-list"
      :options="{
        animation: 200,
        ghostClass: 'ghost'
      }"
      @end="onDragEnd"
    >
      <div v-for="item in fileList" :key="item.uid" class="ant-upload-list-item">
        <img :src="item.thumbUrl" class="ant-upload-list-item-image" />
        <span class="ant-upload-list-item-actions">
          <a-icon type="eye" @click="handlePreview(item)" />
          <a-icon type="delete" @click="removeFile(item)" />
        </span>
      </div>
    </draggable>

    <a-upload
      :show-upload-list="false"
      :before-upload="beforeUpload"
      :customRequest="customRequest"
     
    >
      <div v-if="maxCount == -1 || maxCount > fileList.length" class="ant-upload-btn">
        <a-icon :type="loading ? 'loading' : 'plus'" />
        <div v-show="!loading" class="ant-upload-text">上传</div>
      </div>
    </a-upload>

    <!-- 预览弹窗 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewCancel">
      <img v-if="type === 'img'" alt="example"  style="width: 100%" :src="previewImage" />
      <video v-else-if="type === 'video'" ref="previewVideo"  style="width: 100%" :src="previewImage" :poster="previewImage + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true">
        <source :src="previewImage" type="video/mp4" />
      </video>
      <div v-else>
        <div>
          文件暂不支持在线预览, 请点击下载至本地后, 进行查看 <br /> <a :href="previewImage">下载文件</a>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  props: {
    value: {
      type: [String,Array],
    },
    type: String, // 文件类型, "img" 图片, "video" 视频, null 不限制类型
    // 最大文件大小, 单位 兆M, 默认 20兆
    maxSize: {
      type: Number,
      default: 100
    },
    /*文件夹前缀*/
    prefix: {
      type: String,
      default: "upload",
    },
    // 最大数量, 1 限制1张
    maxCount: {
      type: Number,
      default: -1
    },
    isArrayData: {
      type: Boolean,
      default: false,
    }
  },
  created() {
    this.valueChange(this.value);
  },
  data() {
    return {
      fileList: [], // 文件集合
      loading: false, // 加载状态
      previewVisible: false, // 预览弹窗隐藏/显示
      previewImage: '' // 预览 img url
    }
  },
  methods: {
    // 删除图片
    removeFile(file) {
      // 先更新 fileList
      this.fileList = this.fileList.filter(item => item.uid !== file.uid);
      
      if(this.isArrayData) {
        // 数组模式：直接发送过滤后的 url 数组
        this.$emit('input', this.fileList.map(item => item.url));
      } else {
        // 字符串模式：将过滤后的 url 用逗号连接
        this.$emit('input', this.fileList.map(item => item.url).join(','));
      }
    },
    // 预览按钮点击
    handlePreview(file) {
      this.previewImage = file.url;
      this.previewVisible = true;
    },
    // 上传之前校验
    beforeUpload(file) {
      console.log(file);
      
      // 图片校验
      if (this.type == 'img' && !file.type.startsWith('image')) {
        this.$message.error('上传请上传图片文件');
        return false;
      }
      // 视频校验
      if (this.type == 'video' && !file.type.startsWith('video/mp4')) {
        this.$message.error('上传请上传 mp4 视频文件');
        return false;
      }
      // 大小校验
      if (file.size / 1024 / 1024 > this.maxSize) {
        this.$message.error(`上传文件大小不能超过 ${this.maxSize} MB`);
        return false;
      }
    },
    // 自定义上传请求
    customRequest(fileInfo, index) {
      this.loading = true;

      // 文件
      const uploadFile = fileInfo.file;

      // 文件夹目录
      const prefix = this.prefix
      const url = this.buildOssUrl(uploadFile.name, prefix);

      // oss 上传
      uploadObject(url, uploadFile).then(res => {
        this.fileList.push(this.createAntFileObj(res))
        this.$message.success('上传成功')
        this.loading = false;
        this.$emit('input', this.isArrayData ? this.fileList.map(item => item.url): this.fileList.map(item => item.url).join(','));
      })
    },
    // 构建 oss url
    buildOssUrl(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // 文件随机 uid
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    // 预览关闭
    previewCancel() {
      this.previewVisible = false;
      // 视频暂停播放
      if (this.$refs.previewVideo) {
        this.$refs.previewVideo.pause();
      }
    },
    valueChange(newValue) {
      if (!newValue) {
        this.fileList = [];
        return;
      }
      if(typeof newValue === 'string') {
        const imgUrls = newValue.split(',');
        // 判断 url 是否存在, 不存在进行添加
        imgUrls.forEach(imgUrl => {
          for (let i = 0; i < this.fileList.length; i++) {
            const item = this.fileList[i];
            if (item.url == imgUrl) {
              return;
            }
          }
          this.fileList.push(this.createAntFileObj(imgUrl));
        });
      } else  {
        this.fileList = newValue.map(item => (this.createAntFileObj(item)))
      }

    },
    // 创建 ant fileList 的对象
    createAntFileObj(ossUrl) {
      return {
        status: 'done',
        url: ossUrl.url ?? ossUrl,
        uid: this.getUidRandom(),
        name: ossUrl.url ?? ossUrl,
        thumbUrl: this.getThumbUrl(ossUrl.url ?? ossUrl)
      };
    },
    // 预览图片
    getThumbUrl(ossUrl) {
      switch (this.type) {
        // 视频, 使用 oss 封面图功能, 文档: https://help.aliyun.com/zh/oss/user-guide/video-snapshots
        case "video":
          return ossUrl + "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast";
        // 图片/默认
        case "img":
        default:
          return ossUrl;
      }
    },
    // 拖拽结束后触发
    onDragEnd() {
      if(this.isArrayData) {
        this.$emit('input', this.fileList.map(item => item.url));
      } else {
        this.$emit('input', this.fileList.map(item => item.url).join(','));
      }
    }
  },
  watch: {
    // 监听上级 value 改变
    value(newValue) {
      this.valueChange(newValue);
    }
  }
}
</script>

<style scoped>
.upload-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.ant-upload-list-item {
  width: 104px;
  height: 104px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  position: relative;
  cursor: move;
  overflow: hidden;
}

.ant-upload-list-item:hover .ant-upload-list-item-actions {
  opacity: 1;
}

.ant-upload-list-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.45);
  padding: 8px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.ant-upload-list-item-actions .anticon {
  color: #fff;
  font-size: 16px;
  margin: 0 4px;
  cursor: pointer;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  border: 2px dashed #1890ff;
}

.ant-upload-btn {
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ant-upload-btn:hover {
  border-color: #1890ff;
}
</style>